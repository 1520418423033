import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  eventTopicsData: null,
};

export const getEventTopics = createAsyncThunk(
  "eventTopics/getEventTopics",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`events-topics`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const eventTopicsSlice = createSlice({
  name: "eventTopics",
  initialState,
  reducers: {},
  extraReducers: {
    [getEventTopics.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getEventTopics.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.eventTopicsData = action.payload?.data;
    },
    [getEventTopics.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default eventTopicsSlice.reducer;
