import { combineReducers } from "@reduxjs/toolkit";
import menuSlice from "./slices/menuSlice";
import footerSlice from "./slices/footerSlice";
import homeContentSlice from "./slices/homeContentSlice";
import eventTopicSlice from "./slices/eventTopicsSlice";
import eventsSlice from "./slices/eventsSlice";
import speakerSlice from "./slices/speakerSlice";
import singleEventSlice from "./slices/singleEventSlice";
import singleSpeakerSlice from "./slices/singleSpeakerSlice";
import formSlice from "./slices/formSlice";
import eventUpcomingSlice from "./slices/eventUpcomingSlice";

const rootReducer = combineReducers({
  menu: menuSlice,
  footer: footerSlice,
  homeContent: homeContentSlice,
  eventTopics: eventTopicSlice,
  events: eventsSlice,
  speaker: speakerSlice,
  singleEvent: singleEventSlice,
  singleSpeaker: singleSpeakerSlice,
  form: formSlice,
  upcoming:eventUpcomingSlice
});

export default rootReducer;
