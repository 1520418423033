import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "../styles/common.scss";
import CommonLoader from "../util/CommonLoader";
import ScrollToTop from "./ScrollToTop";


const HomePage = React.lazy(() => import("../Pages/Home"));
const EventDetailPage = React.lazy(() => import("../Pages/EventDetails"));
const SuggestTopicPage = React.lazy(() => import("../Pages/SuggestTopic"));
const UpcomingEventsPage = React.lazy(() => import("../Pages/UpcomingEvents"));
const TopDemandingPage = React.lazy(() => import("../Pages/TopDemanding"));
const AskAQuestionPage = React.lazy(() => import("../Pages/AskAQuestion"));
const WatchNowDetailPage = React.lazy(() => import("../Pages/WatchNowDetail"));
const PageNotFoundPage = React.lazy(() => import("../Pages/PageNotFound"));
const SpeakerMobilePage = React.lazy(() => import("../Pages/SpeakerMobile"));

const EventMobileFilterPage = React.lazy(() =>
  import("../Pages/EventFilterMobile")
);

const App = () => {
  return (
    <Router>
      <Suspense
        fallback={
          <div>
            <CommonLoader />
          </div>
        }
      >
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/event/:slug" component={EventDetailPage} />
          <Route exact path="/suggestopic" component={SuggestTopicPage} />
          <Route exact path="/upcomingevents" component={UpcomingEventsPage} />
          <Route exact path="/events/:slug" component={TopDemandingPage} />
          <Route exact path="/speaker/:url" component={AskAQuestionPage} />
          <Route exact path="/watchnowdetails" component={WatchNowDetailPage} />
          <Route exact path="/eventfilter" component={EventMobileFilterPage} />
          <Route exact path="/mentors" component={SpeakerMobilePage} />

          <Route component={PageNotFoundPage} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default App;
