import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  eventsData: null,
  eventResponsiveTitle:null
};

export const getEvents = createAsyncThunk(
  "events/getEvents",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`events`,{params});
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setResponsiveTile:(state,action)=>{
      state.eventResponsiveTitle=action?.payload
    }

  },
  extraReducers: {
    [getEvents.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getEvents.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.eventsData = action.payload?.data;
    },
    [getEvents.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export const {setResponsiveTile}=eventsSlice.actions;

export default eventsSlice.reducer;


