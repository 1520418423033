import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  speakerData: null,
  selectSpeakerData: null,


};

export const getSpeaker = createAsyncThunk(
  "speaker/getSpeaker",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`speakers/?slug=${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
    
  }
);

export const getSelectSpeaker = createAsyncThunk(
  "selectSpeaker/getSelectSpeaker",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`select-speakers`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
    
  }
);

const speakerSlice = createSlice({
  name: "speaker",
  initialState,
  reducers: {},
  extraReducers: {
    [getSpeaker.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getSpeaker.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.speakerData = action.payload?.data;
    },
    [getSpeaker.rejected]: (state, action) => {
      state.status = "failed";
    },
    [getSelectSpeaker.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getSelectSpeaker.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.selectSpeakerData = action.payload?.data;
    },
    [getSelectSpeaker.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default speakerSlice.reducer;
