import React from "react";
import Style from './CommonLoader.module.scss'

const CommonLoader = () => {
  return (
    <div className={Style.loadingWrap}>
      <div className={Style.dotsAnim}>
      </div>
      <div className={Style.dotsAnim}>
      </div>
      <div className={Style.dotsAnim}>
      </div>
    </div>
  );
};

export default CommonLoader;
