import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  menuData: null,
  menuTitle:null

};

export const getMenu = createAsyncThunk(
  "menu/getMenu",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`header-menu`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {

    setMenuTitle:(state,action)=>{
      state.menuTitle=action?.payload
    }


  },
  extraReducers: {
    [getMenu.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getMenu.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.menuData = action.payload?.data;
    },
    [getMenu.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});


export const {setMenuTitle}=menuSlice.actions;


export default menuSlice.reducer;
