import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  upcomingData: null,
};

export const getUpcoming = createAsyncThunk(
  "upcoming/getUpcoming",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`event-filter`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const upcomingSlice = createSlice({
  name: "upcoming",
  initialState,
  reducers: {},
  extraReducers: {
    [getUpcoming.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getUpcoming.fulfilled]: (state, action) => {
      state.status = "succeeded";
      if (action.payload?.data.status) {
        state.upcomingData = action.payload?.data?.data;
      } else {
        state.upcomingData = null;
      }
    },
    [getUpcoming.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default upcomingSlice.reducer;
