import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: null,
  message: null,
  formKey: null,
};

export const getFormKey = createAsyncThunk(
  "form/getFormKey",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`create-nonce`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitForm = createAsyncThunk(
  "form/submitForm",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`insert-form-register`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const suggestForm = createAsyncThunk(
  "form/suggestForm",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`insert-form-suggest-topic`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const askQuestionForm = createAsyncThunk(
  "form/askQuestionForm",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`insert-form-ask-ques`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {},
  extraReducers: {
    [submitForm.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [submitForm.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [submitForm.rejected]: (state, action) => {
      state.status = "failed";
    },
    [suggestForm.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [suggestForm.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [suggestForm.rejected]: (state, action) => {
      state.status = "failed";
    },
    [askQuestionForm.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [askQuestionForm.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [askQuestionForm.rejected]: (state, action) => {
      state.status = "failed";
    },
    [getFormKey.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getFormKey.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.formKey = action?.payload?.data;
    },
    [getFormKey.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default formSlice.reducer;
